export const states = [
  ["Arizona", "AZ"],
  ["Alabama", "AL"],
  ["Alaska", "AK"],
  ["Arkansas", "AR"],
  ["California", "CA"],
  ["Colorado", "CO"],
  ["Connecticut", "CT"],
  ["Delaware", "DE"],
  ["Florida", "FL"],
  ["Georgia", "GA"],
  ["Hawaii", "HI"],
  ["Idaho", "ID"],
  ["Illinois", "IL"],
  ["Indiana", "IN"],
  ["Iowa", "IA"],
  ["Kansas", "KS"],
  ["Kentucky", "KY"],
  ["Louisiana", "LA"],
  ["Maine", "ME"],
  ["Maryland", "MD"],
  ["Massachusetts", "MA"],
  ["Michigan", "MI"],
  ["Minnesota", "MN"],
  ["Mississippi", "MS"],
  ["Missouri", "MO"],
  ["Montana", "MT"],
  ["Nebraska", "NE"],
  ["Nevada", "NV"],
  ["New Hampshire", "NH"],
  ["New Jersey", "NJ"],
  ["New Mexico", "NM"],
  ["New York", "NY"],
  ["North Carolina", "NC"],
  ["North Dakota", "ND"],
  ["Ohio", "OH"],
  ["Oklahoma", "OK"],
  ["Oregon", "OR"],
  ["Pennsylvania", "PA"],
  ["Rhode Island", "RI"],
  ["South Carolina", "SC"],
  ["South Dakota", "SD"],
  ["Tennessee", "TN"],
  ["Texas", "TX"],
  ["Utah", "UT"],
  ["Vermont", "VT"],
  ["Virginia", "VA"],
  ["Washington", "WA"],
  ["West Virginia", "WV"],
  ["Wisconsin", "WI"],
  ["Wyoming", "WY"],
];

export const getUrlParameter = (paramName) => {
  let paramValue = null;
  let params = new URLSearchParams(window.location.search);

  for (let p of params) {
    if (p[0].toLocaleLowerCase() === paramName) {
      paramValue = p[1];
    }
  }

  return paramValue;
};

export const getDate = (ini) => {
  return ini
    ? new Date(
        new Date(ini).getUTCFullYear(),
        new Date(ini).getUTCMonth(),
        new Date(ini).getUTCDate()
      )
    : new Date(
        new Date().getUTCFullYear(),
        new Date().getUTCMonth(),
        new Date().getUTCDate()
      );
};

export const dayDiff = (ini, end) => {
  return Math.round((getDate(ini) - getDate(end)) / (1000 * 60 * 60 * 24));
};

export const minutesDiff = (ini, end) => {
  return Math.round((getDate(ini) - getDate(end)) / (1000 * 60));
};

export const upperCaseText = (t) => {
  return t ? t.toString().toUpperCase() : "";
};

export const getMonthName = (i) => {
  const d = new Date();
  d.setMonth(i);
  return d.toLocaleString("default", { month: "long" });
};

export const openURLInNewTab = (url) => {
  const a = document.createElement("a");
  a.href = url;
  a.target = "_blank";
  a.click();
};

export const getStorageValue = (k) => {
  const v = window.localStorage.getItem(k);
  return !v || v === "" || v === "null" ? null : v;
};
