import "./App.css";

import React, { createContext } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import MissedCallForm from "./components/MissedCallForm";

export const UserContext = createContext(null);

function App() {
  /////////////////////////////////////////////// USE THIS FLAG TO PUT EVERYTHING IN MAINTENANCE
  const inMaintenance = false;

  return (
    <>
      {inMaintenance && (
        <div
          style={{
            height: "100%",
            width: "100%",
            textAlign: "center",
            marginTop: "100px",
          }}
        >
          We are doing some maintenance. We will be back soon :)
        </div>
      )}
      {!inMaintenance && (
        <React.StrictMode>
          <UserContext.Provider value={{ accessToken: null, isLoading: false }}>
            <Router forceRefresh>
              <Route
                path="/updateMissedCall"
                component={MissedCallForm}
                exact
              />
            </Router>
          </UserContext.Provider>
        </React.StrictMode>
      )}
    </>
  );
}

export default App;
