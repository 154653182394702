import React, { useContext, useState } from "react";
import { UserContext } from "../../App";
import Loader from "../Loader";
import { query, rest } from "../API";
import { getDate, getUrlParameter } from "../Utils";
import dateFormat from "dateformat";

import { Button, Col, Container, Form, FormCheck, Row } from "react-bootstrap";

const MissedCallForm = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const [yes, setYes] = useState(true);
  const [yesVoicemail, setYesVoicemail] = useState(false);
  const [noAnswered, setNoAnswered] = useState(false);
  const [noWrong, setNoWrong] = useState(false);
  const [noSpam, setNoSpam] = useState(false);

  const [officeStaff, setOfficeStaff] = useState("");
  const [callerName, setCallerName] = useState("");
  const [comments, setComments] = useState("");

  const [appointmentSet, setAppointmentSet] = useState(0);

  const [apptDate, setApptDate] = useState(
    dateFormat(new Date(), "yyyy-mm-dd")
  );
  const [patient1, setPatient1] = useState("");
  const [patient2, setPatient2] = useState("");
  const [patient3, setPatient3] = useState("");
  const [patient4, setPatient4] = useState("");
  const [patient5, setPatient5] = useState("");

  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);

  const context = useContext(UserContext);

  const callId = getUrlParameter("callid");

  if (!error) {
    if (!callId) {
      setError(
        "Invalid Call Information. Please contact DentalMarketing Support."
      );
    } else {
      if (!data) {
        query(
          "Call__c",
          "Id, Name, Order__r.Account.Name",
          "Id='" + callId + "'",
          context,
          true
        ).then((callData) => {
          setData(callData[0]);
        });
      }
    }
  }

  const changeTypeSelection = (setThis) => {
    setYes(false);
    setYesVoicemail(false);
    setNoAnswered(false);
    setNoWrong(false);
    setNoSpam(false);
    setThis(true);
  };

  const submit = async (e) => {
    setSending(true);
    const params = {
      apptdate: dateFormat(getDate(apptDate), "mm/dd/yyyy"),
      validmissedcall: yes
        ? "Yes"
        : yesVoicemail
        ? "Yes - Listened To Voicemail"
        : noAnswered
        ? "No - We Answered This Call"
        : noWrong
        ? "No - Wrong Number"
        : "No - Spam Caller",
      StaffName: officeStaff,
      Howmanyappt: appointmentSet,
      callId: data.Id,
      apptset: appointmentSet > 0 ? "Yes" : "No",
      StaffComment: comments,
      callerName: callerName,
      Patient1Name: appointmentSet > 0 ? patient1 : "",
      Patient2Name: appointmentSet > 1 ? patient2 : "",
      Patient3Name: appointmentSet > 2 ? patient3 : "",
      Patient4Name: appointmentSet > 3 ? patient4 : "",
      Patient5Name: appointmentSet > 4 ? patient5 : "",
    };

    const r = await rest(
      "CallScoreforMissedtype",
      "CallScoreforMissedtype",
      "Submit",
      params,
      context
    );
    setSending(false);

    if (r !== null && r.Message === "success") {
      setSuccess(true);
    } else {
      alert(
        "The connection with the server is lost. Please try to submit the information again. If the issue persists, contact DentalMarketing for support."
      );
    }

    e.preventDefault();
    return false;
  };

  return (
    <>
      {
        <div className="content" style={{ margin: "0", padding: "40px" }}>
          {!success && error && (
            <div className="loading">
              <h4>{error}</h4>
            </div>
          )}
          {!error && success && (
            <div className="loading">
              <h4>The information was submitted successfully</h4>
              <h6>You may now close this tab.</h6>
            </div>
          )}
          {!data && !error && !success && <Loader />}
          {data && !error && !success && (
            <Container fluid>
              <h4>{data.Name}</h4>
              <h6 style={{ marginBottom: "20px" }}>
                {data.Order__r.Account.Name}
              </h6>
              <Form>
                <Row>
                  <Form.Label as="legend" column sm={4}>
                    Was this a valid missed call?
                  </Form.Label>
                  <Col sm={10}>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="yes"
                        checked={yes}
                        onChange={(e) => changeTypeSelection(setYes)}
                      />
                      <label className="form-check-label">Yes</label>
                    </div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="yesVoicemail"
                        checked={yesVoicemail}
                        onChange={(e) => changeTypeSelection(setYesVoicemail)}
                      />
                      <label className="form-check-label">
                        Yes - Listened To Voicemail
                      </label>
                    </div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="noAnswered"
                        checked={noAnswered}
                        onChange={(e) => changeTypeSelection(setNoAnswered)}
                      />
                      <label className="form-check-label">
                        No - We Answered This Call
                      </label>
                    </div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="noWrong"
                        checked={noWrong}
                        onChange={(e) => changeTypeSelection(setNoWrong)}
                      />
                      <label className="form-check-label">
                        No - Wrong Number
                      </label>
                    </div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="noSpam"
                        checked={noSpam}
                        onChange={(e) => changeTypeSelection(setNoSpam)}
                      />
                      <label className="form-check-label">
                        No - Spam Caller
                      </label>
                    </div>
                  </Col>
                </Row>

                <Row
                  style={{
                    display: noAnswered || noSpam || noWrong ? "none" : "flex",
                  }}
                >
                  <Col sm={6}>
                    <Form.Label>Office Staff Full Name</Form.Label>
                    <Form.Control
                      className="mb-2"
                      placeholder="Full Name"
                      value={officeStaff}
                      onChange={(e) => setOfficeStaff(e.target.value)}
                    />
                  </Col>
                  <Col sm={6}>
                    <Form.Label>Caller Name</Form.Label>
                    <Form.Control
                      className="mb-2"
                      placeholder="Name"
                      value={callerName}
                      onChange={(e) => setCallerName(e.target.value)}
                    />
                  </Col>
                </Row>

                <Row
                  style={{
                    display: noAnswered || noSpam || noWrong ? "none" : "flex",
                  }}
                >
                  <Col sm={12}>
                    <Form.Label>Staff Comments</Form.Label>
                    <Form.Control
                      className="mb-2"
                      placeholder="Comments"
                      value={comments}
                      onChange={(e) => setComments(e.target.value)}
                    />
                  </Col>
                </Row>

                <Row
                  style={{
                    display: noAnswered || noSpam || noWrong ? "none" : "flex",
                  }}
                >
                  <Form.Label as="legend" column sm={3}>
                    Any appointment set? How many?
                  </Form.Label>
                  <Col sm={1} style={{ minWidth: "75px" }}>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="noAppt"
                        checked={appointmentSet === 0}
                        onChange={(e) => setAppointmentSet(0)}
                      />
                      <label className="form-check-label">No</label>
                    </div>
                  </Col>
                  <Col sm={1}>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="yesOne"
                        checked={appointmentSet === 1}
                        onChange={(e) => setAppointmentSet(1)}
                      />
                      <label className="form-check-label">1</label>
                    </div>
                  </Col>
                  <Col sm={1}>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="yesTwo"
                        checked={appointmentSet === 2}
                        onChange={(e) => setAppointmentSet(2)}
                      />
                      <label className="form-check-label">2</label>
                    </div>
                  </Col>
                  <Col sm={1}>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="yesThree"
                        checked={appointmentSet === 3}
                        onChange={(e) => setAppointmentSet(3)}
                      />
                      <label className="form-check-label">3</label>
                    </div>
                  </Col>
                  <Col sm={1}>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="yesFour"
                        checked={appointmentSet === 4}
                        onChange={(e) => setAppointmentSet(4)}
                      />
                      <label className="form-check-label">4</label>
                    </div>
                  </Col>
                  <Col sm={1}>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="yesFive"
                        checked={appointmentSet === 5}
                        onChange={(e) => setAppointmentSet(5)}
                      />
                      <label className="form-check-label">5</label>
                    </div>
                  </Col>
                </Row>

                <Row
                  style={{
                    display: noAnswered || noSpam || noWrong ? "none" : "flex",
                  }}
                >
                  <Col
                    sm={6}
                    style={{ display: appointmentSet >= 1 ? "block" : "none" }}
                  >
                    <Form.Label>Appointment Date</Form.Label>
                    <Form.Control
                      className="mb-2"
                      type="date"
                      value={apptDate}
                      onChange={(e) => {
                        try {
                          setApptDate(e.target.value);
                        } catch (err) {
                          console.log("Invalid date");
                        }
                      }}
                    />
                  </Col>
                  <Col
                    sm={6}
                    style={{ display: appointmentSet >= 1 ? "block" : "none" }}
                  >
                    <Form.Label>Patient Name #1</Form.Label>
                    <Form.Control
                      className="mb-2"
                      placeholder="Name"
                      value={patient1}
                      onChange={(e) => setPatient1(e.target.value)}
                    />
                  </Col>
                  <Col
                    sm={6}
                    style={{ display: appointmentSet >= 2 ? "block" : "none" }}
                  >
                    <Form.Label>Patient Name #2</Form.Label>
                    <Form.Control
                      className="mb-2"
                      placeholder="Name"
                      value={patient2}
                      onChange={(e) => setPatient2(e.target.value)}
                    />
                  </Col>
                  <Col
                    sm={6}
                    style={{ display: appointmentSet >= 3 ? "block" : "none" }}
                  >
                    <Form.Label>Patient Name #3</Form.Label>
                    <Form.Control
                      className="mb-2"
                      placeholder="Name"
                      value={patient3}
                      onChange={(e) => setPatient3(e.target.value)}
                    />
                  </Col>
                  <Col
                    sm={6}
                    style={{ display: appointmentSet >= 4 ? "block" : "none" }}
                  >
                    <Form.Label>Patient Name #4</Form.Label>
                    <Form.Control
                      className="mb-2"
                      placeholder="Name"
                      value={patient4}
                      onChange={(e) => setPatient4(e.target.value)}
                    />
                  </Col>
                  <Col
                    sm={6}
                    style={{ display: appointmentSet >= 5 ? "block" : "none" }}
                  >
                    <Form.Label>Patient Name #5</Form.Label>
                    <Form.Control
                      className="mb-2"
                      placeholder="Name"
                      value={patient5}
                      onChange={(e) => setPatient5(e.target.value)}
                    />
                  </Col>
                </Row>

                <Button
                  variant="primary"
                  type="submit"
                  disabled={sending}
                  style={{
                    float: "right",
                    padding: "10px 30px",
                  }}
                  onClick={(e) => submit(e)}
                >
                  Submit
                </Button>

                <br />
              </Form>
            </Container>
          )}
        </div>
      }
    </>
  );
};

export default MissedCallForm;
